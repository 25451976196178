.actions {
  border-top: 1px solid #eef0f3;
  padding: 1rem 2rem;
  justify-content: space-between;
}

.entryRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.attachmentDetails {
  height: 75vh;
  object-fit: contain;
  width: 100%;
}

.entryDetails {
  width: 100%;
  display: flex;
}

.details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.icon {
  margin-right: 24px;
  margin-top: 2px;
  width: 16px;
}

.bold {
  font-weight: 600;
}

.client {
  font-weight: 700;
  font-size: 18px;
  margin-top: -2px;
}

.participant {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
