@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('Inter-Regular.woff2?v=3.11') format('woff2'),
    url('Inter-Regular.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('Inter-Italic.woff2?v=3.11') format('woff2'),
    url('Inter-Italic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('Inter-SemiBold.woff2?v=3.11') format('woff2'),
    url('Inter-SemiBold.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('Inter-SemiBoldItalic.woff2?v=3.11') format('woff2'),
    url('Inter-SemiBoldItalic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('Inter-Bold.woff2?v=3.11') format('woff2'),
    url('Inter-Bold.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('Inter-BoldItalic.woff2?v=3.11') format('woff2'),
    url('Inter-BoldItalic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('Inter-roman.var.woff2?v=3.11') format('woff2');
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('Inter-italic.var.woff2?v=3.11') format('woff2');
}
